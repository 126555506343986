import useAppHeader from './logic/useAppHeader';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import Container from 'react-bootstrap/Container';
import { IconBell, IconPersonCircle, IconGlobe } from '../catalog/icons/icons';
import TimezoneModal from './components/TimezoneModal/TimezoneModal';

import MainLogo from '../../assets/img/avx-cr-logo-main.svg';
import './AppHeader.css';

const AppHeader = () => {

  const {
    pageName, pageIsLoading, appTimezone, setAppTimezone,
    userToken, signOut,
    isNavOpen, toggleNav,
    isTimezoneModalOpen, setIsTimezoneModalOpen,
    routerNavigate,
    authenticatedUser
  } = useAppHeader();

  if(!userToken) return null;
  
  return (
    <>
      <header className="avxAppHeaderWrp">
        
        <Navbar collapseOnSelect className="avxAppHeader" expand="md">
          <Container>
            <Navbar.Brand href="/"><img src={MainLogo} alt="Avaxia Reports" /></Navbar.Brand>

            <div className="avxTopRightMenu">

              <div className="avxIconsWrp">
                <Dropdown className="navNotificationDrop">
                  <Dropdown.Toggle as="button" id="notifToggler" className="navbar-toggler"> <IconBell /> </Dropdown.Toggle>
                  <Dropdown.Menu variant="dark" align="end">
                    <Dropdown.Header>Notification Center</Dropdown.Header>
                    <Dropdown.ItemText>This is a new notification as example 1</Dropdown.ItemText>
                    <Dropdown.ItemText>This is a new notification as example 2</Dropdown.ItemText>
                    <Dropdown.ItemText>This is a new notification as example 3</Dropdown.ItemText>
                    <Dropdown.ItemText>This is a new notification as example 4</Dropdown.ItemText>
                    <Dropdown.ItemText>This is a new notification as example 5</Dropdown.ItemText>
                    <Dropdown.ItemText>This is a new notification as example 6</Dropdown.ItemText>
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown className="navUserDrop">
                  <Dropdown.Toggle as="button" id="userMenuToggler" className="navbar-toggler"> <IconPersonCircle /> </Dropdown.Toggle>
                  <Dropdown.Menu variant="dark" align="end">
                    <Dropdown.Header>
                      <h3>{authenticatedUser?.name}</h3>
                      <h4>{authenticatedUser?.wFor}</h4>
                      <h5>{authenticatedUser?.email}</h5>
                    </Dropdown.Header>
                    <Dropdown.Divider />
                    <Dropdown.Item as="button" onClick={() => setIsTimezoneModalOpen(true)} ><IconGlobe /><label>{appTimezone}</label></Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item as="button" onClick={signOut}>Sign out</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="navCollapseWarp">
                <Navbar.Toggle id="navbar-toggler" aria-controls="responsive-navbar-nav" onClick={toggleNav} />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end" in={isNavOpen}>
                  <Nav>
                    <Nav.Link href="/" onClick={routerNavigate}>Dashboard</Nav.Link>
                    <Nav.Link href="/statistics" onClick={routerNavigate}>Statistics</Nav.Link>
                    <Nav.Link href="/global-report" onClick={routerNavigate}>Global Report</Nav.Link>
                    <Nav.Link href="/settings" onClick={routerNavigate}>Settings</Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </div>

            </div>
          </Container>
        </Navbar>

        <section className="avxAppHero">
          <Container>
            {
              pageName && !pageIsLoading
              ? <h2>{pageName}</h2>
              : <span className="headerSkeleton"></span>
            }
          </Container>
        </section>
      </header>

      {
        isTimezoneModalOpen && 
          <TimezoneModal isOpen={isTimezoneModalOpen} setIsOpen={setIsTimezoneModalOpen} appTimezone={appTimezone} setAppTimezone={setAppTimezone} />
      }
    </>
  )
};

export default AppHeader;