import React, { Suspense, useEffect, useContext} from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';

import AppUIProvider from './core/contexts/AppUIProvider';
import AppAuthProvider, { AppAuthContext } from './core/contexts/AppAuthProvider';
import LoginPage from './pages/LoginPage/LoginPage';
import AppHeader from './components/AppHeader/AppHeader';
import AppLoader from './components/AppLoader/AppLoader';
import AppFooter from './components/AppFooter/AppFooter';
import Chatbot from './components/Chatbot/Chatbot';
import Theater from './components/Theater/Theater';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/stylesheet.css';

const ErrorPage = React.lazy(() => import('./components/ErrorPage/ErrorPage'));

const Home = React.lazy(() => import('./pages/Home/Home'));
const Statistics = React.lazy(() => import('./pages/Statistics/Statistics'));
const GlobalReport = React.lazy(() => import('./pages/GlobalReport/GlobalReport'));
const Settings = React.lazy(() => import('./pages/Settings/Settings'));
const Roles = React.lazy(() => import('./pages/Settings/pages/Roles/Roles'));
const NewRole = React.lazy(() => import('./pages/Settings/pages/Roles/NewRole'));
const RoleDetails = React.lazy(() => import('./pages/Settings/pages/Roles/RoleDetails'));
const Timezones = React.lazy(() => import('./pages/Settings/pages/Timezones/Timezones'));
const Users = React.lazy(() => import('./pages/Settings/pages/Users/Users'));
const UserDetails = React.lazy(() => import('./pages/Settings/pages/Users/UserDetails'));




const MainApp = () => {

  const { userToken, authenticatedUser } = useContext(AppAuthContext);

  useEffect(() => {
    const onPageLoad = () => document.getElementById('avxInitLoader').classList.add('hiddenLoader');
    if(document.readyState === 'complete') onPageLoad();
    else{
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <Router basename="/">
      <AppHeader />

      <Routes>
        {/* Private Routes -> Require Auth */}
        <Route path="/" element={ <ProtectedRoute /> } >
          <Route index element={ <Suspense fallback={<AppLoader type="PAGE" />}><Home name="Dashboard" /></Suspense> } />
          <Route path="statistics" element={ <Suspense fallback={<AppLoader type="PAGE" />}><Statistics name="Statistics" /></Suspense> } />
          
          <Route path="global-report" element={ <ProtectedRoute permission={null} /> }>
            <Route index element={ <Suspense fallback={<AppLoader type="PAGE" />}><GlobalReport name="Global Report" /></Suspense> } />
            <Route path=":statusID" element={ <Suspense fallback={<AppLoader type="PAGE" />}><GlobalReport name="Global Report" /></Suspense> } />
          </Route>

          <Route path="settings" element={ <ProtectedRoute permission={null} /> }>
            <Route index element={ <Suspense fallback={<AppLoader type="PAGE" />}><Settings name="Settings" /></Suspense> } />
            <Route path="roles" element={ <ProtectedRoute permission={null} /> } >
              <Route index element={ <Suspense fallback={<AppLoader type="PAGE" />}><Roles name="Roles" /></Suspense> } />
              <Route path="new" element={ <Suspense fallback={<AppLoader type="PAGE" />}><NewRole name="New role" /></Suspense> } />
              <Route path=":roleID/:roleSlug" element={ <Suspense fallback={<AppLoader type="PAGE" />}><RoleDetails name="Role details" /></Suspense> } />
            </Route>
            <Route path="timezones" element={ <Suspense fallback={<AppLoader type="PAGE" />}><Timezones name="Timezones" /></Suspense> } />
            <Route path="users" element={ <ProtectedRoute permission={null} /> } >
              <Route index element={ <Suspense fallback={<AppLoader type="PAGE" />}><Users name="Users" /></Suspense> } />
               <Route path=":userID" element={ <Suspense fallback={<AppLoader type="PAGE" />}><UserDetails name="User details" /></Suspense> } />
            </Route>
          </Route>
        </Route>

        {/* Public Routes -> No Auth Required */}
        <Route path="/login" element={<Suspense fallback={<AppLoader type="PAGE" />}><LoginPage /></Suspense>} />
        <Route path="*" element={<Suspense fallback={<AppLoader type="PAGE" />}><ErrorPage name="Page not found" /></Suspense>} />
      </Routes>

      {userToken && authenticatedUser && <Chatbot />}
      {userToken && authenticatedUser && <Theater />}
     
      <AppFooter />
    </Router>
  )
};



// main render
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <AppUIProvider>
      <AppAuthProvider>
        <MainApp />
      </AppAuthProvider>
    </AppUIProvider>
  // </React.StrictMode>
);