import { useEffect, useState } from "react";
import { Client } from "paho-mqtt";
import { getTime } from "date-fns";

const MQTT_BROKER_URL = process.env.REACT_APP_BROKER_HOST || null;
const MQTT_USERNAME   = process.env.REACT_APP_BROKER_USER || null;
const MQTT_PASSWORD   = process.env.REACT_APP_BROKER_PASSWORD || null;

const client = new Client(MQTT_BROKER_URL, "MCA-MIN-REPORTS-"+getTime(new Date()));

export const useMqtt = (topic) => {

  const [newBrokerMessage, setNewBrokerMessage] = useState(null);

  useEffect(() => {
    if(!client.isConnected()){
      console.log('Broker connecting..');
      client.connect({
        userName: MQTT_USERNAME,
        password: MQTT_PASSWORD,
        onSuccess: () => {
          console.log('Broker connected..');
          client.subscribe(topic, { qos: 0 });

          client.onMessageArrived = (message) => {
            setNewBrokerMessage(message.payloadString);
          };
        },
      });
    }

    return () => {
      if(client.isConnected()) client.disconnect(() => {console.log('Broker disconnected..');});
    };
  }, []); // eslint-disable-line

  return {
    newBrokerMessage
  };
};
