import { useContext, useCallback, useState, useEffect } from 'react';
import { AppAuthContext } from '../../core/contexts/AppAuthProvider';
import { useNavigate } from 'react-router-dom';
import authService from '../services/authService';

import httpMain from '../apis/httpMain';

import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { loginRequest } from '../apis/httpMsal';
import { InteractionStatus, EventType } from '@azure/msal-browser';

const useAuth = () => {

  const [authLoading, setAuthLoading] = useState(false);
  const [authError, setAuthError] = useState('');
  const {
    userToken, setUserToken,
    refreshToken, setRefreshToken,
    authenticatedUser, setAuthenticatedUser
  } = useContext(AppAuthContext);
  const navigate = useNavigate();

  const [authenticatedUserLoading, setAuthenticatedUserLoading] = useState(true);

  
  const signIn = async (data) => {
    setAuthError('');
    setAuthLoading(true);
    
    try{
      const response = await authService.signIn(data);
      if(response.data){
        const { accessToken, refreshToken } = response.data;
        setUserToken( accessToken );
        setRefreshToken( refreshToken );
        navigate('/');
      }
    }catch(error){
      if(error.response.data){
        const errorCode = error.response.data.code;
        switch (errorCode) {
          case 6009:
          case 6010: setAuthError('Access Denied !'); break;
          case 6004: setAuthError('Wrong Username / Password !'); break;
          case 6003: setAuthError('Suspended Account !'); break;
          default: setAuthError('An error has occurred !');
        }
      }else{
        setAuthError('An error has occurred !');
      }
    }finally{
      setAuthLoading(false);
    }
  };


  const signOut = () => {
    if(isMicrosoftAzureAuthenticated) handleMicrosoftAzureLogout();
    setUserToken(null);
    setRefreshToken(null);
    navigate('/login', {replace: true});
  };




  const { instance: microsoftAzureInstance, accounts: microsoftAzureAccounts, inProgress: microsoftAzureInProgress } = useMsal();
  const isMicrosoftAzureAuthenticated = useIsAuthenticated();

  const [isMicrosoftAzureAuthLoading, setIsMicrosoftAzureAuthLoading] = useState(false);
  const [isMicrosoftAzureAuthPrompted, setIsMicrosoftAzureAuthPrompted] = useState(false);


  const handleMicrosoftAzureLogin = () => {
    setIsMicrosoftAzureAuthLoading(true);
    try{
      microsoftAzureInstance.loginRedirect(loginRequest);
    }catch(e){
      console.error("AVX MSAL ERROR", e);
    }
  };


  const handleMicrosoftAzureLogout = () => {
    try{
      microsoftAzureInstance.logout();
    }catch(e){
      console.error("avx msal out error", e);
    }
  };


  useEffect(() => {
    const callbackId = microsoftAzureInstance.addEventCallback(({eventType}) => {
      const eventsLoadArray = [EventType.LOGIN_SUCCESS, EventType.ACQUIRE_TOKEN_SUCCESS, EventType.ACQUIRE_TOKEN_START];
      if(eventsLoadArray.includes(eventType)){
        setIsMicrosoftAzureAuthLoading(true);
        setIsMicrosoftAzureAuthPrompted(true);
      }
    });

    return () => {
      if(callbackId) microsoftAzureInstance.removeEventCallback(callbackId);
    } 
  }, []); // eslint-disable-line


  const microsoftAzureSignIn = useCallback(
    async () => {
      if(isMicrosoftAzureAuthPrompted && isMicrosoftAzureAuthenticated && microsoftAzureInProgress === InteractionStatus.None && (!userToken || !authenticatedUser)){
        setIsMicrosoftAzureAuthLoading(true);
        try{
          const silentTokenResponse = await microsoftAzureInstance.acquireTokenSilent({
            ...loginRequest,
            account: microsoftAzureAccounts[0]
          });
          
          const { data } = await authService.microsoftAzureSignIn(silentTokenResponse.accessToken);
          if(!data) throw new Error(7004);
          const { accessToken, refreshToken } = data;
          setUserToken( accessToken );
          setRefreshToken( refreshToken );
          navigate('/');
        }catch(error){
          if(error.response?.data){
            const errorCode = error.response.data.code;
            switch (errorCode) {
              case 6009:
              case 6010: 
              case 6004: setAuthError('Access Denied !'); break;
              case 6003: setAuthError('Suspended Account !'); break;
              default: setAuthError('An error has occurred !');
            }
          }else setAuthError('An error has occurred !');
        }finally{
          setIsMicrosoftAzureAuthLoading(false);
          setIsMicrosoftAzureAuthPrompted(false);
        }
      }
    },
    [
      isMicrosoftAzureAuthPrompted, microsoftAzureInstance, microsoftAzureInProgress, isMicrosoftAzureAuthenticated, microsoftAzureAccounts, 
      userToken, authenticatedUser,
      navigate, setUserToken, setRefreshToken
    ]
  );


  const authInterception = () => {
    httpMain.interceptors.response.use(
      (response) => { 
        return response;
      },
      (error) => {
        const statusCode = error.response.status;
        if(statusCode === 401){
          const responseURL = error.response.request.responseURL;
          const responseRoute = responseURL.replace(process.env.REACT_APP_MCA_CORE_URL, '');
          const codesArray = [6001, 6004, 6009, 6010]; //suspended / notFound / token invalid / token expired
          const errorCode = error.response.data.code;
    
          if(responseRoute !== '/public/mca-signin' && codesArray.includes(errorCode)){
            signOut();
          }
        }
  
        return Promise.reject(error);
      }
    );
  }


  const getUserData = useCallback(
    async () => {
      setAuthenticatedUserLoading(true);
      try{
        const reponse = await authService.getUserData();
        if(reponse.data){
          setAuthenticatedUser(reponse.data);
        }
      }catch(error){
        //console.log('API ERROR :', error);
      }finally{
        setAuthenticatedUserLoading(false);
      }
    }

  , [setAuthenticatedUser]);


  const getUserPermissions = () => {

  }

  
  return {
    authLoading,
    authError,
    userToken,
    refreshToken,
    signIn,
    signOut,
    authInterception,

    isMicrosoftAzureAuthLoading,
    handleMicrosoftAzureLogin,
    microsoftAzureSignIn,

    authenticatedUserLoading,
    authenticatedUser,
    getUserData,
    getUserPermissions
  }
};

export default useAuth;