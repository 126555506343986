import { createContext, useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { utcToZonedTime, format } from "date-fns-tz";
import fromUnixTime from 'date-fns/fromUnixTime';

export const AppUIContext = createContext();

const AppUIProvider = ({ children }) => {
  const [theme, setTheme] = useState('light');
  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  const [pageName, setPageName] = useState('');
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [appTimezone, setAppTimezone] = useLocalStorage('mcaAppTZ');
  const getAppTimezonedDate = (date, stringFormat, unix = false) => {
    if(unix) date = fromUnixTime(date);
    return format(utcToZonedTime(date, appTimezone), stringFormat);
  }
  const [theaterImgUrl, setTheaterImgUrl] = useState();

  const providerValue = { 
    theme, toggleTheme, 
    pageName, setPageName, 
    pageIsLoading, setPageIsLoading,
    appTimezone, setAppTimezone,
    getAppTimezonedDate,
    theaterImgUrl, setTheaterImgUrl
  };

  return (
    <AppUIContext.Provider value={ providerValue }>
      {children}
    </AppUIContext.Provider>
  )
};

export default AppUIProvider;