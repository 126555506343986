import httpMain from "../apis/httpMain";
const jwtSign = require('jwt-encode');

const signIn = async data => {

  const loginToken = jwtSign(data, process.env.REACT_APP_AUTH_GEN_KEY, {expiresIn:'2m'});

  const response = await httpMain({
    method: 'POST',
    url: '/public/mca-signin',
    data: {
      auth: loginToken
    }
  });

  return response;
};


const getUserData = async () => {

  const response = await httpMain({
    method: 'GET',
    url: '/users/profile'
  });

  return response;
}


const microsoftAzureSignIn = async token => {
  const response = await httpMain({
    method: 'POST',
    url: '/public/sso/az-signin',
    headers: {
      "sso-az-auth": 'Bearer ' + token
    }
  });

  return response;
};


const authService = {
  signIn,
  getUserData,

  microsoftAzureSignIn
};

export default authService;
