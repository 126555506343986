import httpMain from "../../../../../core/apis/httpMain";

const getTimezones = async () => {
  const response = await httpMain({
    method: 'GET',
    url: `/settings/timezones`
  });

  return response;
};

const addTimezone = async (data) => {
  try{
    const response = await httpMain({
      method: 'POST',
      url: '/settings/timezones',
      data
    });

    return response;
  }catch(e){
    throw e;
  }
};

const deleteTimezone = async (id) => {
  try{
    const response = await httpMain({
      method: 'DELETE',
      url: '/settings/timezones/' + id
    });

    return response;
  }catch(e){
    throw e;
  }
};




const timezonesService = {
  getTimezones,
  addTimezone,
  deleteTimezone
};

export default timezonesService;